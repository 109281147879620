<!-- =========================================================================================
    File Name: Main.vue
    Description: Main layout
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div>
    <div class="layout--main" :class="[layoutTypeClass, navbarClasses, footerClasses, {'no-scroll': isAppPage}]">
      <div v-if="notificationPermissionModal" class="notification-container flex justify-center items-start px-2">
        <div class="bg-white px-4 pb-4 pt-2" style="border-radius: 0px 0px 17px 17px;">
          <p class="material-icons-outlined flex justify-end text-base cursor-pointer" @click="denyNotificationPermission">close</p>
          <div class="flex items-center gap-2">
            <div class="bg-primary w-fit">
              <span class="material-symbols-outlined text-white p-2 text-6xl">notifications_active</span>
            </div>
            <div>
              <p class="font-semibold text-lg text-black leading-tight">{{$t('Subscribe to ShipBlu notifications to get the important updates.')}}</p>
              <p class="font-semibold text-sm mt-2 leading-tight text-grey">{{$t('You can disable notifications anytime from your browser.')}}</p>
            </div>
          </div>
          <div class="text-right mt-4">
            <button @click="denyNotificationPermission" class="py-2 mr-4 bg-transparent border-none text-grey-dark px-6 cursor-pointer">{{$t('Later')}}</button>
            <vs-button class="py-2 px-6" @click="requestNotificationPermission">{{$t('Allow')}}</vs-button>
          </div>
        </div>
      </div>
      <div v-if="showNotificationAlert" class="notification-container flex justify-center items-start px-2">
        <div class="w-90 bg-white px-4 pb-4 pt-2" style="border-radius: 0px 0px 17px 17px;">
          <p class="material-icons-outlined flex justify-end text-base cursor-pointer" @click="showNotificationAlert = false">close</p>
          <div class="flex items-center gap-2">
            <img src="@/assets/images/logo/logo.png" width="70" alt="">
            <div>
              <p class="font-semibold text-lg text-black leading-tight">{{activeNotification.title}}</p>
              <p class="font-semibold text-sm mt-2 leading-tight">{{activeNotification.body}}</p>
            </div>
          </div>
          <div class="text-right mt-4">
            <vs-button class="py-2 px-6">GO!</vs-button>
          </div>
        </div>
      </div>
      <div id="content-area">
        <div class="content-wrapper">
          <div class="flex items-start">
            <Sidebar :navMenuItems="sidebarItems" @toggleSidebar="isExpanded = $event" class="flex-shrink-0"></Sidebar>
            <div class="py-[32px] lg:px-[24px] px-[16px] h-[100vh] overflow-auto flex-grow sm:mb-0 mb-[86px]" :style="`${ isExpanded ? 'width: calc(100% - 270px)' : 'width: calc(100% - 82px)'}`" id="scrollContainer">
              <transition :name="routerTransition">
                <div v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="router-header flex flex-wrap items-center mb-6">
                  <div v-if="routeTitle" class="content-area__heading mr-4" :class="{'pr-4 border-0 md:border-r border-solid border-grey-light' : $route.meta.breadcrumb}">
                    <h2 class="mb-1">{{ $t(routeTitle) }}</h2>
                  </div>
                  <!-- BREADCRUMB -->
                  <vx-breadcrumb class="md:block hidden" :i18nData="i18nData" :store="$store" v-if="$route.meta.breadcrumb" :route="$route" :lang="$i18n.locale" :isRTL="$vs.rtl" />
                </div>
              </transition>

              <div class="back-to-top hidden" :class="userHasScrolled ? 'show-scroll' : 'hide-scroll'">
                <vs-button @click="toTop" icon-pack="feather" icon="icon-arrow-up" class="shadow-lg btn-back-to-top" />
              </div>

              <div>
                <vs-alert v-if="!isOnline" icon-pack="feather" icon="icon-alert-circle" color="warning" class="mb-4">{{$t('App is running in offline mode.')}}</vs-alert>
                <vs-alert color="success" style="height: fit-content;" v-if="user.is_self_signup && user.self_signup_verified !== null && (new Date().getDate() - new Date(user.created).getDate() < 7)" icon-pack="material-icons" icon="celebration" class="mb-4">
                  {{$t('Congratulations! Your account is now verified and you can request a pickup for your orders. Pickups requested before')}} {{pickupTimeDic[user.pickup_time]}} {{$t('will be picked on the same day.')}}
                </vs-alert>
                <vs-alert style="height: fit-content;" v-if="user.is_self_signup && user.self_signup_verified === null && user.name === 'Entrepreneur Merchant' && $route.name !== 'merchant-complete-profile'" icon-pack="material-icons" icon="celebration" color="warning" class="mb-4">
                  {{$t('Your merchant profile is incomplete. You can explore around with limited functionality.')}} 
                  <span class="font-bold underline cursor-pointer" @click="goToCompleteProfile()">{{$t('Click here')}}</span>
                  {{$t('to complete profile.')}}
                </vs-alert>
                <vs-alert style="height: fit-content;" v-if="user.is_self_signup && user.self_signup_verified === null && user.name !== 'Entrepreneur Merchant'" icon-pack="material-icons" icon="sentiment_very_satisfied" color="primary" class="mb-4">
                  <span>{{$t('Thank you for signing up! Your account is under review, and pickup requests are temporarily disabled until the review is complete. To ship your orders today, simply drop off your packages at the nearest hub. Contact customer support to locate it—we’re here to help!')}} </span>
                </vs-alert>
              </div>

              <transition :name="routerTransition" mode="out-in">
                <router-view @changeRouteTitle="changeRouteTitle" @setAppClasses="(classesStr) => $emit('setAppClasses', classesStr)" />
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hub-details :hubDetails="hubDetails" :hubDetailsModal="hubDetailsModal" @hubDetailsModal="hubDetailsModal = $event"/>
  </div>
</template>


<script>
import navMenuItems        from '@/layouts/merchant/navMenuItems.js'
import themeConfig         from '@/../themeConfig.js'
import Sidebar            from '@/layouts/merchant/Sidebar.vue'
import i18nData            from '../../i18n/i18nData.js'
import {sendRequest, sendFulfillmentRequest} from '../../http/axios/requestHelper.js'
import common from '../../assets/utils/common.js'
import VueJwtDecode from 'vue-jwt-decode'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import HubDetails from '../../views/merchant/components/HubDetails.vue'

export default {
  components: {
    Sidebar,
    HubDetails
  },
  data () {
    return {
      isExpanded: true,
      pickupPoints: [],
      dynamicWatchers   : {},
      footerType        : themeConfig.footerType  || 'static',
      hideScrollToTop   : themeConfig.hideScrollToTop,
      isNavbarDark      : false,
      navbarColor       : themeConfig.navbarColor || '#fff',
      navbarType        : themeConfig.navbarType  || 'floating',
      navMenuItems,
      routerTransition  : themeConfig.routerTransition || 'none',
      routeTitle        : this.$route.meta.pageTitle,
      i18nData,
      merchantData      : {},
      userMerchant      : {},
      enableFulfillment : process.env.VUE_APP_ENABLE_FULFILLMENT,
      userHasScrolled   : false,
      user: {
        pricing_lookup_delivery: {
          package: {}
        },
        address: {
          zone: {
            city: {
              governorate: {}
            }
          }
        }
      },
      pickupTimeDic: {
        1: '1 pm',
        2: '3 pm'
      },
      hubDetailsModal: false,
      showNotificationAlert: false,
      activeNotification: {},
      notificationPermissionModal: false,
      notificationPermission: '',
      hubDetails: {},
      releaseVersion: process.env.VUE_APP_RELEASE_VERSION,
      sidebarItems: [],
      childMerchants: []
    }
  },
  watch: {
    notificationPermission (val) {
      if (val === 'granted' && this.userMerchant.email) {
        const messaging = getMessaging()
        onMessage(messaging, (payload) => {
          this.showNotificationAlert = true
          this.activeNotification = payload.notification
        })
        getToken(messaging, { vapidKey: process.env.VUE_APP_CLOUD_MESSAGING_ID}).then((currentToken) => {
          if (currentToken) {
            const data = {
              token: currentToken,
              email: this.userMerchant.email
            }
            this.sendFirebaseToken(data)
          }
        })
      }
    },
    '$route' () {
      this.routeTitle = this.$route.meta.pageTitle
    },
    hubDetailsModal (val) {
      if (val) this.getHubDetails()
    }
  },
  computed: {
    bodyOverlay () { return this.$store.state.bodyOverlay },
    footerClasses () {
      return {
        'footer-hidden': this.footerType === 'hidden',
        'footer-sticky': this.footerType === 'sticky',
        'footer-static': this.footerType === 'static'
      }
    },
    isAppPage () {
      return this.$route.meta.no_scroll
    },
    isThemeDark ()     { return this.$store.state.theme === 'dark' },
    layoutTypeClass () { return `main-${this.mainLayoutType}`      },
    mainLayoutType ()  { return this.$store.state.mainLayoutType   },
    navbarClasses ()   {
      return {
        'navbar-hidden'   : this.navbarType === 'hidden',
        'navbar-sticky'   : this.navbarType === 'sticky',
        'navbar-static'   : this.navbarType === 'static',
        'navbar-floating' : this.navbarType === 'floating'
      }
    },
    verticalNavMenuWidth () { return this.$store.state.verticalNavMenuWidth },
    windowWidth ()          { return this.$store.state.windowWidth }
  },
  methods: {
    denyNotificationPermission () {
      this.notificationPermissionModal = false
      this.$cookies.set('requestNotification', true, '1w')
    },
    requestNotificationPermission () {
      if (Notification.permission !== 'granted') {
        Notification.requestPermission().then(() => {
          this.notificationPermissionModal = false
        })
      }
    },
    toTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    changeRouteTitle (title) {
      this.routeTitle = title
    },
    updateFooter (val) {
      this.footerType = val
    },
    updateRouterTransition (val) {
      this.routerTransition = val
    },
    toggleHideScrollToTop (val) {
      this.hideScrollToTop = val
    },
    loadMerchantFulfillment (merchantID) {
      sendFulfillmentRequest(false, false, this, `api/v1/fc/merchants/${merchantID}/`, 'get', null, true,
        () => {
          if (this.enableFulfillment === 'true') {
            this.sidebarItems = this.navMenuItems.map(route => {
              return {
                ...route,
                items: this.userMerchant.access_financials === true ? route.items : route.items.filter(item => item.access_financials === true) 
              }
            })
          }
        },
        () => {}
      )
    },
    loadPickupPoints (merchantID) {
      sendRequest(false, false, this, `api/v1/merchants/${merchantID}/pickup-points/?limit=60`, 'get', null, true,
        (response) => {
          this.pickupPoints = response.data.results
        }
      )
    },
    sendFirebaseToken (data) {
      sendRequest(false, false, this, 'api/v1/users/device-tokens/', 'post', data, true,
        () => {
        }
      )
    },
    loadClarityScript () {
      (function (c, l, a, r, i, t, y) {
        c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) }
        t = l.createElement(r); t.async = 1; t.src = `https://www.clarity.ms/tag/${  i}`
        y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y)
      })(window, document, 'clarity', 'script', 'lek3fv4r64')
      const checkClarityLoaded = setInterval(() => {
        if (window.clarity) {
          clearInterval(checkClarityLoaded)
          this.setCustomTags()
        }
      }, 100)
    },
    setCustomTags () {
      if (process.env.VUE_APP_RELEASE_VERSION && process.env.VUE_APP_RELEASE_VERSION.startsWith('v')) {
        const merchantData = common.checkMerchantInLocalStorage(this)
        merchantData.then(results => {
          this.user = results.merchantData
          // eslint-disable-next-line no-undef
          clarity('set', 'role', 'Merchant')
          // eslint-disable-next-line no-undef
          clarity('set', 'merchant_name', this.user.name)
          // eslint-disable-next-line no-undef
          clarity('set', 'email', results.userMerchantData.email)
          // eslint-disable-next-line no-undef
          if (this.user.address && this.user.address.zone && this.user.address.zone.city && this.user.address.zone.city.governorate) {
            // eslint-disable-next-line no-undef
            clarity('set', 'zone', this.user.address.zone.name)
            // eslint-disable-next-line no-undef
            clarity('set', 'gov', this.user.address.zone.city.governorate.name)
            // eslint-disable-next-line no-undef
            clarity('set', 'city', this.user.address.zone.city.name)
          }
          if (this.user.pricing_lookup_delivery && this.user.pricing_lookup_delivery.package) {
            // eslint-disable-next-line no-undef
            clarity('set', 'price_plan', this.user.pricing_lookup_delivery.package.name)
          }
          // eslint-disable-next-line no-undef
          clarity('set', 'Status', this.user.status)
          // eslint-disable-next-line no-undef
          clarity('set', 'committed_volume', this.user.committed_volume)
        })
      }
    },
    checkNotificationPermission () {
      this.notificationPermissionModal = this.notificationPermission === 'default' && this.$cookies.get('requestNotification') === null
    },
    getHubDetails () {
      sendRequest(false, false, this, `api/v1/warehouses/?id=${this.pickupPoints.filter(item => item.is_default)[0].address.zone.served_by}`, 'get', null, true, 
        (response) => {
          this.hubDetails = response.data.results[0]
        }
      )
    },
    initFormBricks () {
      const userId = this.userMerchant.merchant
      const attributes = {
        MerchantName: `${this.user.name  } - ${  this.user.display_name}`,
        Email: this.userMerchant.email,
        UserName: `${this.userMerchant.first_name  } - ${  this.userMerchant.last_name}`
      }
      const environmentId = this.releaseVersion === 'staging' ? 'cm1z6ul5v0002znc83akqtfz6' : 'cm1z6ul8a0006znc8fthqafhq'
      const formBricks = document.createElement('script')
      formBricks.setAttribute('type', 'text/javascript')
      formBricks.setAttribute('async', !0)
      formBricks.setAttribute('src', this.releaseVersion === 'staging' ? 'https://app.formbricks.com/api/packages/js' : 'https://app.formbricks.com/js/formbricks.umd.cjs')
      const e = document.getElementsByTagName('script')[0]
      e.parentNode.insertBefore(formBricks, e)
      setTimeout(function () {
        if (typeof window !== 'undefined' && window.formbricks) {
          window.formbricks.init({
            environmentId,
            apiHost: 'https://app.formbricks.com',
            userId,
            attributes
          })
        }
      }, 500)
    },
    loadGoogleAnalytics () {
      if (!document.querySelector('script[src="https://www.googletagmanager.com/gtag/js?id=G-RQ3DDVPNCK"]')) {
        const script = document.createElement('script')
        script.async = true
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-RQ3DDVPNCK'
        document.head.appendChild(script)
        script.onload = function () {
          window.dataLayer = window.dataLayer || []
          function gtag () {
            window.dataLayer.push(arguments)
          }
          gtag('js', new Date())
          gtag('config', 'G-RQ3DDVPNCK')
        }
      }
    },
    loadSidebarItems () {
      this.sidebarItems = this.navMenuItems.filter(item => item.access_fulfillment === false)
    },
    loadSubMerchants (merchantID) {
      sendRequest(true, false, this, `api/v1/merchants/${merchantID}/sub-merchants/`, 'get', null, true, 
        (response) => {
          this.childMerchants = response.data
          this.childMerchants.length > 0 ? localStorage.setItem('haveSubAccounts', true) : localStorage.removeItem('haveSubAccounts')
        }
      )
    },
    goToCompleteProfile () {
      this.$router.push({ name: 'merchant-complete-profile' })
        .catch(() => {})
    }
  },
  mounted () {
    this.loadClarityScript()
  },
  created () {
    this.loadSidebarItems()
    if (!common.isIOS()) {
      this.notificationPermission = (typeof Notification !== 'undefined') ? Notification.permission : ''
      this.checkNotificationPermission()
    }
    if (this.notificationPermission === 'denied') {
      Notification.requestPermission()
    }
    if (localStorage.getItem('showDeliverAgainMessage') === null) {
      localStorage.setItem('showDeliverAgainMessage', true)
    }
    const userInfo = localStorage.getItem('accessToken') ? VueJwtDecode.decode(localStorage.getItem('accessToken')) : {}
    if (!userInfo[`${process.env.VUE_APP_API_URL}/app_metadata`]['terms_conditions']) {
      this.$acl.change('guest')
      this.$router.push(`/${this.$cookies.get('language')}/pages/terms-and-conditions`).catch(() => {})
    } else {
      this.$store.dispatch('updateUserInfo', {
        displayName: userInfo.name,
        email: userInfo.email,
        photoURL: userInfo.picture,
        providerId: userInfo.sub.length > 0 ? userInfo.sub.substr(0, userInfo.sub.indexOf('|')) : '',
        uid: userInfo.sub,
        userRole: userInfo[`${process.env.VUE_APP_API_URL}/role`][0]
      })
      localStorage.setItem('is_fulfillment', userInfo[`${process.env.VUE_APP_API_URL}/app_metadata`].is_fulfillment)
      this.$acl.change(this.$store.state.AppActiveUser.userRole)
    }
    const query = {
      ...this.$route.query,
      page: Number(this.$route.query.page) > 0 ? this.$route.query.page : 1
    }
    if (this.$route.query && this.$route.query.page < 0) {
      this.$router.push({
        query
      }).catch(() => {})
    }
    localStorage.setItem('multipleWarehouses', true)
    if (this.$cookies.get('language') === 'en') {
      this.$i18n.locale = 'en'
      this.$vs.rtl = false
    } else {
      this.$i18n.locale = 'ar'
      this.$vs.rtl = true
    }
    window.onscroll = () => {
      if (document.documentElement.scrollTop > 300) {
        this.userHasScrolled = true
      } else {
        this.userHasScrolled = false
      }
    }
    if (this.$cookies.get('gotVerifyAlert') === null) {
      this.$cookies.set('gotVerifyAlert', false)
    }
    const merchantData = common.checkMerchantInLocalStorage(this)
    merchantData.then(results => {
      this.userMerchant = results.userMerchantData
      this.user = results.merchantData
      window.fcWidgetMessengerConfig = {
        version: 2,
        siteId: process.env.VUE_APP_API_URL,
        firstName: `${`${this.userMerchant.first_name  } ${  this.userMerchant.last_name}`} (${this.user.name})`,
        lastName: this.userMerchant.last_name,
        email: this.userMerchant.email,
        phone: this.userMerchant.phone,
        meta: {
          cf_sb_account_name: this.user.name,
          cf_sb_account_id: this.userMerchant.merchant
        }
      }
      const fcWidget = document.createElement('script')
      fcWidget.setAttribute('src', 'https://fw-cdn.com/11720815/4351362.js')
      fcWidget.setAttribute('chat', 'true')
      fcWidget.setAttribute('widgetId', 'c8ddebb6-1c79-435d-b4fa-c1b0e60fdf0c')
      document.head.appendChild(fcWidget)
      if (this.notificationPermission === 'granted') {
        const messaging = getMessaging()
        onMessage(messaging, (payload) => {
          this.showNotificationAlert = true
          this.activeNotification = payload.notification
        })
        getToken(messaging, { vapidKey: process.env.VUE_APP_CLOUD_MESSAGING_ID}).then((currentToken) => {
          if (currentToken) {
            const data = {
              token: currentToken,
              email: this.userMerchant.email
            }
            this.sendFirebaseToken(data)
          }
        }).catch(() => {})
      }
      if (userInfo[`${process.env.VUE_APP_API_URL}/app_metadata`].is_fulfillment && this.user.status === 'active') {
        this.loadMerchantFulfillment(this.userMerchant.merchant) 
      }
      this.loadPickupPoints(this.userMerchant.merchant)
      this.loadSubMerchants(this.userMerchant.merchant)
      if (this.userMerchant.access_financials === false) {
        this.sidebarItems = this.sidebarItems.map(route => {
          return {
            ...route,
            items: route.items.filter(item => item.access_financials === true)
          }
        })
      }
      this.merchantData = {
        id: this.user.id,
        firstName: this.user.name,
        lastName: this.user.subscription.name
      }
      this.initFormBricks()
    })
    this.loadGoogleAnalytics()
  }
}

</script>
<!-- <script src='https://fw-cdn.com/11720815/4351362.js' chat='true' widgetId='c8ddebb6-1c79-435d-b4fa-c1b0e60fdf0c'></script> -->
<style lang="scss">
iframe[title="Message from company"],
iframe[title="Close message"] {
  display: block !important;
}
#launcher {
  display: block;
}

#fc_frame {
  z-index: 9999 !important;
  right: 24px !important;
}
[dir=rtl] #fc_frame {
  right: unset !important;
  left: 24px;
}
@media (max-width: 575px) {
  #fc_frame {
    bottom: 110px !important;
  }
}
.back-to-top {
  position: fixed;
  z-index: 99999;
  bottom: 85px;
  right: 35px;
  transition: 0.5s;
}
.hide-scroll {
  opacity: 0;
}
.show-scroll {
  opacity: 1;
}
.notification-container {
  position: fixed;
  inset: 0;
  background: rgba(72, 72, 72, 0.64);
  z-index: 9999999;
}
.w-fit {
  width: 70px;
}
</style>