/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
export default [
  {
    id: 1,
    url: '/merchant/dashboard',
    name: 'Dashboard',
    i18n: 'Dashboard',
    slug: 'merchant-dashboard',
    items: [],
    access_fulfillment: false,
    icon: 'HomeIcon',
    iconLibrary: 'feather'
  },
  {
    id: 2,
    url: '/merchant/notifications',
    name: 'Notifications',
    i18n: 'Notifications',
    slug: 'merchant-notifications',
    items: [],
    access_fulfillment: false,
    icon: 'BellIcon',
    iconLibrary: 'feather'
  },
  {
    id: 3,
    header: 'Shipping',
    shortHeader: 'SH',     
    i18n: 'Shipping',
    icon: 'PackageIcon',
    iconLibrary: 'feather',
    access_fulfillment: false,
    items: [
      {
        id: 1,
        name: 'Orders',
        i18n: 'OrdersTab',
        slug: 'shipments',
        icon: 'PackageIcon',
        iconLibrary: 'feather',
        access_financials: true,
        submenu: [
          {
            url: '/merchant/orders/delivery-orders',
            name: 'Delivery',
            i18n: 'Delivery',
            slug: 'merchant-shipments',
            icon: 'arrow-right',
            iconLibrary: 'phosphor'
          },
          {
            url: '/merchant/orders/returns',
            name: 'Returns',
            i18n: 'ReturnsTab',
            slug: 'merchant-returns-view',
            icon: 'arrow-left',
            iconLibrary: 'phosphor'
          },
          {
            url: '/merchant/orders/exchanges',
            name: 'Exchanges',
            i18n: 'ExchangeTab',
            slug: 'merchant-exchanges-view',
            icon: 'RepeatIcon',
            iconLibrary: 'feather'
          },
          {
            url: '/merchant/orders/cash-collections',
            name: 'Cash Collection',
            i18n: 'CashCollectionTab',
            slug: 'merchant-cash-collections-view',
            icon: 'money',
            iconLibrary: 'phosphor'
          }
        ]
      },
      {
        id: 2,
        url: '/merchant/orders/supplies-shop',
        name: 'Supplies Shop',
        i18n: 'Supplies Shop',
        slug: 'merchant-supplies-shop',
        icon: 'local_mall',
        iconLibrary: 'material',
        access_financials: true
      },
      {
        id: 3,
        url: '/merchant/action-center',
        name: 'Action Center',
        i18n: 'Action Center',
        slug: 'merchant-action-center',
        icon: 'ads_click',
        iconLibrary: 'material',
        access_financials: true
      },
      {
        id: 4,
        url: '/merchant/analytics',
        name: 'Analytics',
        i18n: 'Analytics',
        slug: 'merchant-analytics',
        icon: 'insert_chart',
        iconLibrary: 'material',
        access_financials: true
      }
    ]
  },
  {
    id: 4,
    header: 'Fulfillment',
    shortHeader: 'FL',
    i18n: 'Fulfillment',
    icon: 'LayersIcon',
    items: [
      {
        id: 5,
        name: 'Orders',
        i18n: 'OrdersTab',
        slug: 'shipments',
        icon: 'PackageIcon',
        iconLibrary: 'feather',
        access_financials: true,
        submenu: [
          {
            url: '/merchant/fulfillment/outbound-orders',
            name: 'Delivery',
            i18n: 'Delivery',
            slug: 'merchant-fulfillment-outbound-orders',
            icon: 'arrow-right',
            iconLibrary: 'phosphor'
          },
          {
            url:  '/merchant/fulfillment/inbound-orders',
            name: 'Returns',
            i18n: 'ReturnsTab',
            slug: 'merchant-fulfillment-inbound-orders',
            icon: 'arrow-left',
            iconLibrary: 'phosphor'
          }
        ]
      },
      {
        id: 6,
        url: '/merchant/fulfillment/product-catalog',
        name: 'Catalog',
        i18n: 'Catalog',
        slug: 'merchant-fulfillment-product-catalog',
        icon: 'import_contacts',
        iconLibrary: 'material',
        access_financials: true
      },
      {
        id: 7,
        name: 'Stockk',
        i18n: 'Stock',
        icon: 'stack',
        iconLibrary: 'phosphor',
        submenu: [
          {
            url: '/merchant/fulfillment/stock-requests',
            name: 'Request',
            i18n: 'Request',
            slug: 'merchant-fulfillment-stock-requests',
            icon: 'add_diamond',
            iconLibrary: 'material'
          },
          {
            url: '/merchant/fulfillment/unidentified-stock-requests',
            name: 'Unidentified Requests',
            slug: 'merchant-fulfillment-unidentified-stock-requests',
            i18n: 'Unidentified Requests',
            icon: 'warning-diamond',
            iconLibrary: 'phosphor'
          },
          {
            url: '/merchant/fulfillment/stock-recalls',
            name: 'Stock Recalls',
            i18n: 'Stock Recalls',
            slug: 'merchant-fulfillment-stock-recalls',
            icon: 'CornerDownLeftIcon',
            iconLibrary: 'feather'
          }
        ],
        access_financials: true
      }
    ],
    access_fulfillment: true
  },
  {
    id: 5,
    header: 'Account',
    shortHeader: 'AC',
    i18n: 'Account',
    items: [
      {
        id: 8,
        name: 'Finances',
        i18n: 'Finances',
        icon: 'wallet',
        iconLibrary: 'phosphor',
        access_financials: false,
        submenu: [
          {
            url: '/merchant/account',
            query: '?tab=overview',
            name: 'merchant-account',
            i18n: 'OverviewTab',
            slug: 'merchant-account',
            icon: 'bid_landscape',
            iconLibrary: 'material'
          },
          {
            url: '/merchant/account',
            query: '?tab=billing&page=1',
            name: 'merchant-account',
            i18n: 'Billing',
            slug: 'merchant-account',
            icon: 'receipt_long',
            iconLibrary: 'material'
          },
          // {
          //   url: '/merchant/account',
          //   query: '?tab=transactions&page=1',
          //   name: 'merchant-account',
          //   i18n: 'Transactions',
          //   slug: 'merchant-account',
          //   icon: 'arrows-left-right',
          //   iconLibrary: 'phosphor'
          // },
          {
            url: '/merchant/payout-methods',
            name: 'Payout Methods',
            i18n: 'Payout Methods',
            slug: 'merchant-payout-methods',
            icon: 'cardholder',
            iconLibrary: 'phosphor'
          }
        ]
      },
      {
        id: 9,
        url: '/merchant/pricing',
        name: 'Pricing',
        i18n: 'Pricing',
        slug: 'merchant-pricing',
        icon: 'DollarSignIcon',
        iconLibrary: 'feather',
        access_financials: false
      },
      {
        id: 10,
        name: 'Subscriptions',
        i18n: 'Subscriptions',
        icon: 'event_repeat',
        iconLibrary: 'material',
        access_financials: false,
        submenu: [
          {
            url: '/merchant/shipblu-shield',
            name: 'ShipBlu Shield',
            i18n: 'ShipBlu Shield',
            slug: 'merchant-shipblu-shield',
            icon: 'ShieldIcon',
            iconLibrary: 'feather'
          }
        ]
      },
      {
        id: 11,
        url: '/merchant/integrations/ecommerce-store',
        name: 'Integrations',
        i18n: 'Integrations',
        slug: 'integrations',
        icon: 'GitPullRequestIcon',
        iconLibrary: 'feather',
        access_financials: true
      }
    ],
    access_fulfillment: false
  }
]

